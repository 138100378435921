import { render, staticRenderFns } from "./culture_index.vue?vue&type=template&id=ed05b24c&scoped=true"
import script from "./culture_index.vue?vue&type=script&lang=js"
export * from "./culture_index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed05b24c",
  null
  
)

export default component.exports